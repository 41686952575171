/* Klachtenpagina */
.field-name-field-video-klachten {
  margin: 0 auto;
  width: 33%;
}

.node-type-klachtenpagina .field-name-field-accordion-title {
  color: #5fbee8;
  display: inline-block;
  font-size: 20px;
}

.node-type-klachtenpagina .field-name-field-accordion-title:hover {
  cursor: pointer;
}

.node-type-klachtenpagina .field-collection-item-field-accordion-content .content .field-name-field-accordion-title .field-item:before  {
  content: '\f055';
  display: inline-block;
  font-family: FontAwesome;
  padding-right: 10px;
}

.node-type-klachtenpagina .field-collection-item-field-accordion-content .content.active .field-name-field-accordion-title .field-item:before  {
  content: '\f056';
}

.node-type-klachtenpagina .field-name-field-accordion-content .field-collection-view {
    padding: 1em 0 1.5em;
}

.node-type-klachtenpagina .field-collection-container {
  border-bottom: none;
}

.node-type-klachtenpagina .field-name-field-accordion-content .field-collection-view-final {
  border-bottom: 1px dotted #d3d7d9;
  margin: 0 1em;
}

.node-type-klachtenpagina .field-name-field-link-klachten {
  margin: 2em 0 0 0.95em;
}

.node-type-klachtenpagina .field-name-field-link-klachten a {
  background: #4c98b9 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 2px;
  color: #fff;
  font-weight: 600;
  padding: 12px 15px;
  transition: all 0.2s ease 0s;
  width: auto;
}

.node-type-klachtenpagina .field-collection-container li::before {
  bottom: 3px;
  color: #e96f8f;
  content: "";
  font-family: "FontAwesome";
  font-size: 0.6em;
  margin-left: 30px;
  padding-right: 15px;
  position: relative;
  text-align: right;
}

/* Klachtenformulier */
.webform-component--klacht--ik-laat-mij-ondersteunen-door-een-patientenvertrouwenspersoon {
  clear: both;
  padding-top: 1em;
}

#edit-klacht-meerdere-bijlagen-meesturen {
  border: 1px solid #eeeeee;
  clear: both;
  display: block;
  padding-right: 1em;
}

#edit-klacht-meerdere-bijlagen-meesturen {
  clear: both;
  display: block;
  margin: 1em 0;
}

div.webform-container-inline.webform-datepicker div.form-item.form-type-select .form-select {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 2px solid #ededed;
  border-radius: 3px;
  color: gray;
  font-weight: 600;
  line-height: 1em;
  padding: 10px;
}

div.webform-container-inline.webform-datepicker .webform-calendar {
  padding: 1em 0 0 1em;
}

ul.tabs.primary {
  position: relative;
  z-index: 100;
}

.view-online-hulpsoorten .button {
  margin: 0;
}

div#edit-submitted-klacht-wilt-u-een-of-meerdere-bijlagen-meesturen-ajax-wrapper .description {
  margin-bottom: 10px;
}

#edit-submitted-klacht-wilt-u-een-of-meerdere-bijlagen-meesturen-upload-button,
#edit-submitted-klacht-wilt-u-een-of-meerdere-bijlagen-meesturen-upload,
#edit-submitted-klacht-bijlage-2-upload,
#edit-submitted-klacht-bijlage-3-upload {
  border: 1px solid #eeeeee;
  clear: both;
  display: inline-block;
  margin: 5px 0;
  padding-right: 1em;
  width: 50%;
}

#edit-submitted-klacht-wilt-u-een-of-meerdere-bijlagen-meesturen-upload-button,
#edit-submitted-klacht-bijlage-2-upload-button,
#edit-submitted-klacht-bijlage-3-upload-button {
  display: inline-block;
  margin-left: 10px;
  width: auto;
}

#edit-submitted-klacht-bijlage-2-ajax-wrapper .description,
#edit-submitted-klacht-bijlage-3-ajax-wrapper .description {
  display: none;
}

.view-online-hulpsoorten .button a {
  color: #ffffff;
}

#webform-client-form-395 div.form-item.form-type-radio {
  display: block;
}

#webform-client-form-395 div.form-item.form-type-radio:first-child {
  padding-left: 1.8em;
}

#webform-client-form-395 .webform-calendar.webform-calendar-start-1917-05-31.webform-calendar-end-2017-05-31.webform-calendar-day-1.hasDatepicker {
  background: none;
}
